import { API, API_V2 } from "@/utils/HttpUtils"

export const updateAccessToken = (token) => {
  return API_V2.post(
    "admin/auth/refresh/",
    {},
    {
      headers: {
        Authorization: "Bearer " + token
      }
    }
  )
}

export const fetchProfile = () => {
  return API.get("/users-management/users/profile")
}

export const sendLegacyLogoutRequest = async () => {
  try {
    await API.post("/auth/logout")
    return Promise.resolve()
  } catch {
    return Promise.resolve()
  }
}

export const sendLogoutRequest = () => {
  return API_V2.post("admin/auth/logout/")
}

export const handleLoginLegacy = (payload) => {
  return API.post("/auth/login", payload)
}

export const handleLogin = (payload) => {
  return API_V2.post("admin/auth/login/", payload)
}

export const setApiAuthHeader = (token) => {
  API_V2.defaults.headers.common["Authorization"] = token
}
